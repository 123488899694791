const Bars = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={24}
    viewBox="0 0 28 24"
    fill="none"
    {...props}
  >
    <path
      d="M27.25 3.574H.75A.758.758 0 010 2.81V.766C0 .343.336 0 .75 0h26.5c.414 0 .75.343.75.766v2.043a.758.758 0 01-.75.765zm0 10.213H.75a.758.758 0 01-.75-.766V10.98c0-.423.336-.766.75-.766h26.5c.414 0 .75.343.75.766v2.042a.758.758 0 01-.75.766zm0 10.213H.75a.758.758 0 01-.75-.766v-2.043c0-.422.336-.765.75-.765h26.5c.414 0 .75.343.75.766v2.042a.758.758 0 01-.75.766z"
      fill="#fff"
    />
  </svg>
);

export default Bars;
