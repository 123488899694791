import Head from "next/head";
import { ReactChildren, ReactNode } from "react";
import Header from "../Header/Header";

const Layout = ({
  auth,
  children,
  seo,
}: {
  auth: boolean;
  children: ReactChildren | ReactNode;
  seo: {
    title: string;
    description?: string;
    path?: string;
    index: boolean;
    follow: boolean;
  };
}) => {
  return (
    <div className="bg-gradient-to-b from-primary-100 to-black min-h-screen">
      <Head>
        <title>{seo.title}</title>
        <meta property="og:title" content={seo.title} />
        <meta property="og:site_name" content="NuCalm® Stress Assessment" />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/nucalmv3-dev.appspot.com/o/NuCalmVoice%2FSocial.png?alt=media&token=923a2842-777b-4d48-96f2-34f6e1d935ad"
        />
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.path && (
          <meta property="og:url" content={`${process.env.HOST}/${seo.path}`} />
        )}
        <meta
          name="robotos"
          content={`${seo.index || "noindex"}, ${seo.follow || "nofollow"}`}
        />
      </Head>
      <Header auth={auth} />
      {children}
      {/* TODO: Add in / create footer */}
    </div>
  );
};

export default Layout;
