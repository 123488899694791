const HeaderLogo = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={160}
    height={32}
    viewBox="0 0 160 32"
    fill="none"
    {...props}
  >
    <path
      d="M1.075 1.994h4.707l15.474 19.917V5.359c0-3.39 4.12-4.087 4.997-4.087V30.8h-4.251L6.072 10.305v16.934c0 3.61-4.16 3.913-4.993 3.913L1.075 1.994zM31.673 22.907V9.07h4.996v12.346c0 3.376 1.69 5.271 4.663 5.271 2.896 0 4.91-1.978 4.91-5.35V9.063h5.036V31.16c-.956 0-5.036 0-5.036-3.61v-.123c-1.402 2.058-3.425 3.827-6.807 3.827-4.913 0-7.762-3.293-7.762-8.347zM56.006 16.478v-.069c0-8.191 6.155-14.898 14.844-14.898 5.366 0 8.584 1.851 11.35 4.483l-3.24 3.737c-2.353-2.141-4.79-3.542-8.132-3.542-5.449 0-9.493 4.487-9.493 10.126v.094c0 5.636 4.005 10.206 9.533 10.206 3.552 0 5.818-1.4 8.295-3.704l3.258 3.292c-3.012 3.127-6.314 5.101-11.72 5.101-8.46-.01-14.695-6.552-14.695-14.826zm28.725 8.068v-.083c0-4.693 3.715-6.996 9.051-6.996 1.99-.02 3.968.287 5.858.906v-.52c0-3.086-1.897-4.733-5.405-4.733-2.147.004-4.268.461-6.224 1.343l-1.365-3.91a18.945 18.945 0 018.255-1.768c3.258 0 5.699.863 7.223 2.426 1.611 1.563 2.353 3.87 2.353 6.707v13.603c-.992 0-4.87 0-4.87-3.25v-.187c-1.484 1.769-3.754 3.17-7.1 3.17-4.101 0-7.776-2.347-7.776-6.708zm14.982-1.563V21.5a13.863 13.863 0 00-4.953-.862c-3.219 0-5.116 1.357-5.116 3.61v.104c0 2.098 1.857 3.293 4.25 3.293 3.302-.011 5.819-1.906 5.819-4.66zm10.257-18.05c0-3.314 3.823-4.007 5.036-4.007v29.873h-5.036V4.933zm10.626 4.137h5.068v3.292c1.402-1.931 3.302-3.743 6.644-3.743 3.139 0 5.366 1.523 6.517 3.826 1.734-2.303 4.048-3.826 7.35-3.826 4.742 0 7.635 3.003 7.635 8.303V31.16c-1.18 0-4.996-.123-4.996-3.61v-9.094c0-3.458-1.611-5.31-4.417-5.31-2.723 0-4.663 1.891-4.663 5.39V30.81h-5.018V18.413c0-3.376-1.64-5.253-4.406-5.253-2.767 0-4.646 2.043-4.646 5.375V30.81h-5.068V9.07zM155.494 10.579h-.724v-.4h1.995v.4h-.724v2.148h-.525l-.022-2.148zM157.073 10.178h.615c.214.527.514 1.293.623 1.607.105-.314.405-1.083.622-1.607h.601v2.549h-.496c0-.614 0-1.426.019-1.805-.134.397-.478 1.184-.725 1.805h-.047c-.26-.635-.611-1.444-.724-1.805.026.386.022 1.21.026 1.805h-.496l-.018-2.549z"
      fill="#fff"
    />
  </svg>
);

export default HeaderLogo;
