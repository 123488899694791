import { AssessmentTypes } from "interfaces/assessment.types";
import { QuestionKeyTypes } from "./assessments";

export default {
  LOGIN: "/login",
  SIGNUP: "/signup",
  START: "/start",
  ASSESSMENT_QUESTION: (
    type: AssessmentTypes,
    questionId: QuestionKeyTypes | string
  ) => `/start/${type}/${questionId}`,
  FORGOT_PASSWORD: "/forgot-password",
  ASSESSMENT_ID: (id: string) => `/assessments/${id}`,
  VERIFY_EMAIL: "/verify-email",
  API: {
    LOGOUT: "/api/auth/logout",
    LOGIN: "/api/auth/login",
  },
};
