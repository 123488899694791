import HeaderLogo from "@SVG/HeaderLogo";
import Bars from "@SVG/Bars";
import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import client from "firebase/client";
import Times from "@SVG/Times";
import { useRouter } from "next/router";
import Container from "components/Container/Container";
import axios from "axios";
import routes from "constants/routes";

const Header = ({ auth }: { auth?: boolean }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const router = useRouter();
  const node = useRef(null);

  const handleLogOut = async () => {
    try {
      await client.auth().signOut();
      await axios.post(routes.API.LOGOUT);
      return router.push(routes.LOGIN);
    } catch (e) {
      throw e;
    }
  };

  const handleClick = (e: MouseEvent) => {
    // @ts-ignore
    if (node && node.current && node?.current.contains(e.target)) {
      return;
    }
    setIsMenuOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
      setIsMenuOpen(false);
    };
  }, []);

  return (
    <div className="bg-transparent">
      <Container width="full">
        <nav className="flex flex-row justify-between">
          <HeaderLogo />
          <button
            className="cursor-pointer outline-none sm:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <Bars />
          </button>
          <ul className="hidden sm:flex sm:flex-row sm:justify-between sm:list-none">
            {!auth && (
              <li
                className="no-underline text-white font-medium text-right flex" // {s.listItems}
              >
                <Link href={routes.LOGIN} passHref>
                  <a className="text-sm no-underline font-bold text-white pl-5 m-auto sm:text-lg lg:text-xl">
                    Login
                  </a>
                </Link>
              </li>
            )}
            {!auth && (
              <li className="no-underline text-white font-medium text-right flex">
                <Link href={routes.SIGNUP}>
                  <a className="text-sm no-underline font-bold text-white pl-5 m-auto sm:text-lg lg:text-xl">
                    Signup
                  </a>
                </Link>
              </li>
            )}
            {auth && (
              <li className="no-underline text-white font-medium text-right flex">
                <Link href={routes.START} passHref>
                  <a className="text-sm no-underline font-bold text-white pl-5 m-auto sm:text-lg lg:text-xl">
                    Start New Assessment
                  </a>
                </Link>
              </li>
            )}
            <li className="no-underline text-white font-medium text-right flex">
              <Link href="mailto:voice@nucalm.com" passHref>
                <a className="text-sm no-underline font-bold text-white pl-5 m-auto sm:text-lg lg:text-xl">
                  Support
                </a>
              </Link>
            </li>

            {auth && (
              <li className="no-underline text-white font-medium text-right flex">
                <button
                  className="text-sm no-underline font-bold text-white pl-5 m-auto sm:text-lg lg:text-xl focus:outline-none"
                  onClick={handleLogOut}
                >
                  Log out
                </button>
              </li>
            )}
          </ul>
          {isMenuOpen && (
            <div
              ref={node}
              className="absolute top-0 right-0 bg-white w-screen h-96 z-10 shadow-lg rounded-bl-full" //{s.navMobileMenu}
            >
              <div className="p-5 relative flex justify-end">
                <div className="absolute left-6 top-6">
                  <button
                    className="cursor-pointer outline-none"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    <Times />
                  </button>
                </div>
                <ul className="list-none text-primary-100 w-96">
                  {!auth && (
                    <li className="pb-5 text-right">
                      <Link shallow href={routes.LOGIN} passHref>
                        <a className="text-2xl no-underline font-bold text-primary-100">
                          Login
                        </a>
                      </Link>
                    </li>
                  )}
                  {!auth && (
                    <li className="pb-5 text-right">
                      <Link href={routes.SIGNUP}>
                        <a className="text-2xl no-underline font-bold text-secondary-500">
                          Signup
                        </a>
                      </Link>
                    </li>
                  )}
                  {auth && (
                    <li className="pb-5 text-right">
                      <Link href={routes.START} passHref>
                        <a className="text-2xl no-underline font-bold text-primary-100">
                          Start New Assessment
                        </a>
                      </Link>
                    </li>
                  )}
                  <li className="pb-5 text-right">
                    <Link href="mailto:voice@nucalm.com" passHref>
                      <a className="text-2xl no-underline font-bold text-primary-100">
                        Support
                      </a>
                    </Link>
                  </li>
                  {auth && (
                    <li className="pb-5 text-right">
                      <button
                        className="text-2xl no-underline font-bold text-secondary-500 focus:outline-none"
                        onClick={handleLogOut}
                      >
                        Log out
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          )}
        </nav>
      </Container>
    </div>
  );
};

export default Header;
