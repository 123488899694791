const Times = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M8.479 12L.425 3.97a1.188 1.188 0 010-1.683L2.296.422a1.197 1.197 0 011.688 0l8.053 8.03L20.091.423a1.197 1.197 0 011.688 0l1.871 1.865a1.188 1.188 0 010 1.683L15.596 12l8.054 8.029a1.188 1.188 0 010 1.683l-1.871 1.865a1.197 1.197 0 01-1.689 0l-8.053-8.03-8.053 8.03a1.197 1.197 0 01-1.689 0L.424 21.71a1.188 1.188 0 010-1.683L8.479 12z"
        fill="#001828"
      />
    </svg>
  );
};

export default Times;
