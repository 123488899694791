import cx from "classnames";
import { HTMLProps } from "react";
interface IContainer extends HTMLProps<HTMLDivElement> {
  width: "sm" | "md" | "lg" | "full";
  children: React.ReactNode;
}

const Container = (props: IContainer) => {
  let sizeString: string;
  switch (props.width) {
    case "sm":
      sizeString = "max-w-lg";
      break;
    case "md":
      sizeString = "max-w-2xl";
      break;
    case "lg":
      sizeString = "max-w-4xl";
      break;
    default:
      sizeString = "max-w-full";
      break;
  }

  return (
    <div
      className={cx("container p-6 mx-auto", sizeString, props.className || "")}
    >
      {props.children}
    </div>
  );
};

export default Container;
